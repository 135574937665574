// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Widget
\* ------------------------------------------------------------------------ */

// callout variant

.widget.-callout {
    .widget_title {
        font-size: remify(55, 16);
    }
}
