// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Widget
\* ------------------------------------------------------------------------ */

// callout variant

.widget.-callout {
    .widget_title {
        font-size: calc(#{(27.5em / 16)} + (#{(55 / 16)} - #{(27.5 / 16)}) * ((100vw - #{(480em / 16)}) / (#{(1280 / 16)} - #{(480 / 16)})));
    }
}
