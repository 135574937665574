// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Content
\* ------------------------------------------------------------------------ */

.content_post {
    & {
        padding: remify(($content-padding * 1.333333333333333) ($content-padding * 1.666666666666667));
    }

    // not iddeal, but since it's a shortcode I don't have much choice...

    .interactive-graphic {
        margin-left: remify(-$content-padding * 1.666666666666667);
        margin-right: remify(-$content-padding * 1.666666666666667);
    }
}

.content_sidebar_inner {
    & {
        padding: remify(($content-padding * 1.666666666666667) ($content-padding * 0.666666666666667));
    }
}

.content_sidebar_fader {
    & {
        background: linear-gradient(to bottom, lighten(desaturate(adjust-hue($primary, 29.2223), 9.9965), 58.2353) 0%, $background 100%);
        height: 100%;
    }
}

.content_cta {
    & {
        margin: remify(70 0, 16);
    }

    @media screen and (max-width: remify($site-width + (($content-padding * 1.666666666666667) * 2), 16)) {
        margin-left: remify($content-padding * 1.666666666666667);
        margin-right: remify($content-padding * 1.666666666666667);
    }
}

// alt variant

.content-block.-alt {
    .content_inner {
        padding-bottom: remify(90);
        padding-top: remify(90);
    }

    & + & > .content_inner {
        border-top-width: remify(10);
    }
}

// alt fullbleed variant

.content-block.-alt.-fullbleed {
    & {
        padding-bottom: remify(90);
        padding-top: remify(90);
    }

    & + & {
        border-top-width: remify(10);
    }

    > .content_inner {
        padding-bottom: 0;
        padding-top: 0;
    }

    & + & > .content_inner {
        border-top-width: 0;
    }
}

.content-block.-bordered {
    & {
        padding-top: remify(43);
        padding-bottom: remify(10);
    }
}
