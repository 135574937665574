// Styles written by Jordan Bearce @ Weblinx, Inc.

.gallery .gallery-item {
    float: right;
}

.gallery-columns-1 .gallery-item {
    width: (100% / 1);
}

.gallery-columns-2 .gallery-item {
    width: (100% / 2);
}

.gallery-columns-3 .gallery-item {
    width: (100% / 3);
}

.gallery-columns-4 .gallery-item {
    width: (100% / 4);
}

.gallery-columns-5 .gallery-item {
    width: (100% / 5);
}

.gallery-columns-6 .gallery-item {
    width: (100% / 6);
}
