// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Swiper (http://idangero.us/swiper/)
\* ------------------------------------------------------------------------ */

// Custom

.swiper-image {
    .swiper-container.-fixedheight & {
        height: remify(420, 16);
    }
}

.swiper-title {
    & {
        font-size: remify(79.97, 16);
    }
}
